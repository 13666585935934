/* eslint-disable no-underscore-dangle */
// vendors
import React from 'react';
import { graphql } from 'gatsby';
import { PropTypes } from 'prop-types';

// utils
import generateLangLinks from '../../utils/generateLangLinks';

// components
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';

// views
import HeroSection from '../../views/ProductTemplateView/HeroSection';
import ReasonsSection from '../../views/ProductTemplateView/ReasonsSection';
import ModelsSection from '../../views/ProductTemplateView/ModelsSection';

const ProductTemplate = ({ data, pageContext }) => {
  const {
    product: {
      seo,
      localization: { translations },
      claimings: reasons,
      targetAudience,
      callToAction,
      ...product
    },
  } = data;

  const { seoTitle = undefined, metaDescription = undefined } = seo || {};

  const langLinks = generateLangLinks(translations);

  const galleryPictures = product.pictures.map((picture) => ({
    id: picture.asset.id,
    key: picture._key,
    alt: picture.alt,
    caption: picture.caption,
    picture: {
      desktop: picture.asset.slidePictureDesktop,
      mobile: picture.asset.slidePictureMobile,
    },
    thumbnail: {
      desktop: picture.asset.slideThumbnailDesktop,
      mobile: picture.asset.slideThumbnailMobile,
    },
  }));

  return (
    <Layout localeLinks={langLinks}>
      <SEO
        lang={pageContext.langKey}
        title={seoTitle}
        description={metaDescription}
        langLinks={langLinks}
      />

      <HeroSection
        title={product.title}
        rawShortDescription={product._rawShortDescription}
        pictures={galleryPictures}
        isConsumer={targetAudience !== 'company'}
        callToAction={callToAction}
      />

      {reasons.length > 0 && <ReasonsSection reasons={reasons} />}

      {product.models.length > 0 && <ModelsSection models={product.models} />}
    </Layout>
  );
};

ProductTemplate.propTypes = {
  data: PropTypes.shape().isRequired,
  pageContext: PropTypes.shape({
    langKey: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProductTemplate;

export const query = graphql`
  query ProductTemplateQuery($id: String!) {
    product: sanityProduct(id: { eq: $id }) {
      id
      title
      targetAudience
      _rawShortDescription(resolveReferences: { maxDepth: 10 })
      callToAction {
        href
        internalLink
        title
      }
      pictures {
        _key
        alt
        asset {
          id
          slidePictureDesktop: fluid(maxWidth: 664, maxHeight: 678) {
            ...GatsbySanityImageFluid
          }
          slidePictureMobile: fluid(maxWidth: 1039, maxHeight: 902) {
            ...GatsbySanityImageFluid
          }
          slideThumbnailDesktop: fluid(maxWidth: 202, maxHeight: 140) {
            ...GatsbySanityImageFluid
          }
          slideThumbnailMobile: fluid(maxWidth: 342, maxHeight: 236) {
            ...GatsbySanityImageFluid
          }
        }
        caption
      }
      claimings {
        _key
        _rawShortDescription(resolveReferences: { maxDepth: 10 })
        name
        icon {
          asset {
            id
            url
            originalFilename
          }
        }
        actions {
          ... on SanityDownloadAction {
            _key
            _type
            title
            attachment {
              asset {
                id
                url
                originalFilename
              }
            }
          }
          ... on SanityLinkAction {
            _key
            _type
            href
            internalLink
            title
          }
        }
      }
      models {
        _key
        _rawShortDescription(resolveReferences: { maxDepth: 10 })
        name
        descriptiveName
        pictures {
          _key
          alt
          asset {
            id
            slidePictureDesktop: fluid(maxWidth: 664, maxHeight: 678) {
              ...GatsbySanityImageFluid
            }
            slidePictureMobile: fluid(maxWidth: 1039, maxHeight: 902) {
              ...GatsbySanityImageFluid
            }
            slideThumbnailDesktop: fluid(maxWidth: 202, maxHeight: 140) {
              ...GatsbySanityImageFluid
            }
            slideThumbnailMobile: fluid(maxWidth: 342, maxHeight: 236) {
              ...GatsbySanityImageFluid
            }
          }
          caption
        }
        variants {
          _key
          SKU
          technicalSheet {
            asset {
              url
            }
          }
          options {
            ... on SanitySizeVariation {
              _key
              _type
              depth
              length
              height
            }
          }
        }
      }
      seo {
        metaDescription: meta_description
        seoTitle: seo_title
      }
      localization {
        ...alternativeLangHref
      }
    }
  }
`;

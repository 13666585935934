import React from 'react';
import PropTypes from 'prop-types';

import {
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
} from './DataTable.styles';

const DataTable = ({ rows, headers, ...rest }) => {
  const normalizedRows = rows.map((row) => ({
    id: row.id,
    cells: new Array(headers.length),
  }));

  rows.forEach((row, i) => {
    headers.forEach(({ key }, j) => {
      const id = `${row.id}:${key}`;
      normalizedRows[i].cells[j] = {
        id,
        value: row[key],
      };
    });
  });

  return (
    <Table {...rest}>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableHeader key={header.key}>{header.header}</TableHeader>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {normalizedRows.map((row) => (
          <TableRow key={row.id}>
            {row.cells.map((cell) => (
              <TableCell key={cell.id}>{cell.value}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

DataTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default DataTable;

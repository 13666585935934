// vendors
import styled from 'styled-components';
import { em, rem } from 'polished';

// utils
import breakpointsRange from '@utils/breakpointsRange';
import { lessThan } from '@utils/mediaQuery';

// styles
import { titleStyle } from '@styles/global';

export const Container = styled.section`
  padding: ${rem(128, 22)} 24px;

  ${lessThan(480)} {
    padding-top: ${rem(74, 16)};
    padding-bottom: ${rem(74, 16)};
  }
  background-color: ${({ theme }) => theme.colors.veryLightPink};
`;

export const Wrapper = styled.div`
  max-width: 1408px;
  margin: 0 auto;
`;

export const Stack = styled.div``;

export const Title = styled.h3`
  ${titleStyle};
`;

export const Content = styled.div`
  ${({ theme }) =>
    theme.layouts.switcher(3, rem(80, 22), em(theme.breakpoints[3]))}
`;

export const List = styled.ul`
  padding: 0;

  list-style: none;
`;

export const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  text-align: center;
`;

export const ImageTextWrapper = styled.div``;

export const Image = styled.img`
  max-height: 200px;
  ${breakpointsRange(
    [
      {
        prop: 'width',
        sizes: [138, 234],
        bases: [16, 22],
        unit: 'em',
      },
    ],
    [320, 1024]
  )}
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: inherit;

  width: 100%;
  max-width: 416px;
  min-height: 108px;

  margin-top: ${rem(66, 22)};
`;

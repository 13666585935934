/* eslint-disable no-underscore-dangle */
// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { hideVisually } from 'polished';
import { defineMessages, useIntl } from 'react-intl';

// components
import BlockContent from '@components/BlockContent';

// styles
import {
  ActionsWrapper,
  Container,
  Content,
  Wrapper,
  List,
  ListItem,
  ImageTextWrapper,
  Image,
  Title,
} from './ReasonsSection.styles';
import LinkAction from './LinkAction/LinkAction';
import DownloadAction from './DownloadAction/DownloadAction';

const messages = defineMessages({
  sectionTitle: {
    defaultMessage: 'Notre engagement',
    description: 'Title of the reasons section in the product template',
  },
  attachmentButtonAriaRoleDescription: {
    defaultMessage: 'Bouton de téléchargement de pièce jointe',
    description:
      'Aria role description of the attachment button in the product template',
  },
});

const ReasonsSection = ({ reasons }) => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <Wrapper>
        <h2 css={hideVisually}>{formatMessage(messages.sectionTitle)}</h2>

        <Content>
          <List>
            {reasons.map((node) => (
              <ListItem key={node._key}>
                <ImageTextWrapper>
                  <Image src={node.icon.asset.url} alt='' />

                  <Title>{node.name}</Title>

                  <BlockContent blocks={node._rawShortDescription} />
                </ImageTextWrapper>

                {node.actions.length > 0 && (
                  <ActionsWrapper>
                    {node.actions.map((action) => {
                      if (action._type === 'linkAction')
                        return (
                          <LinkAction
                            key={action._key}
                            href={action.href}
                            internal={action.internalLink}
                          >
                            {action.title}
                          </LinkAction>
                        );

                      if (action._type === 'downloadAction')
                        return (
                          <DownloadAction
                            key={action._key}
                            fileName={action.attachment.asset.originalFilename}
                            url={action.attachment.asset.url}
                          >
                            {action.title}
                          </DownloadAction>
                        );

                      return null;
                    })}
                  </ActionsWrapper>
                )}
              </ListItem>
            ))}
          </List>
        </Content>
      </Wrapper>
    </Container>
  );
};

ReasonsSection.propTypes = {
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string.isRequired,
      _rawShortDescription: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      name: PropTypes.string.isRequired,
      icon: PropTypes.shape({
        asset: PropTypes.shape({
          id: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
          originalFilename: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      actions: PropTypes.arrayOf(PropTypes.shape()),
    })
  ),
};

ReasonsSection.defaultProps = {
  reasons: [],
};

export default ReasonsSection;

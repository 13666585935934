/* eslint-disable no-underscore-dangle */
// vendors
import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { hideVisually, rem } from 'polished';
import { Transition } from 'react-spring/renderprops';
import { defineMessages, useIntl } from 'react-intl';

import { greaterThan, greaterThanCondition } from '@utils/mediaQuery';

// images
import ToggleIcon from '@images/ToggleIcon/ToggleIcon';

// components
import BlockContent from '@components/BlockContent';
import SideBarLayout from '@components/SideBarLayout';
import Button from '@components/Button';
import DataTable from '@components/DataTable';
import Gallery from '@components/Carousel';

// styles
import IconDownload from '@src/images/IconDowload';
import {
  Container,
  Wrapper,
  DescriptionContainer,
  List,
  ListItem,
  Title,
  SubTitle,
  SizesFormatsContainer,
} from './ModelsSection.styles';

const messages = defineMessages({
  sectionTitle: {
    defaultMessage: 'Nos modèles',
    description: 'Title of the models section in the product template',
  },
  buttonText: {
    defaultMessage: 'Tailles et formats',
    description: 'Text of the sizes/formats button in the product template',
  },
  toggleIconLabelCollapse: {
    defaultMessage: 'Réduire',
    description: 'Label of the toggle icon in the product template',
  },
  toggleIconLabelExpand: {
    defaultMessage: 'Développer',
    description: 'Label of the toggle icon in the product template',
  },
  dataTableHeaderModel: {
    defaultMessage: 'Modèles',
    description: 'Header name of the models data table in the product template',
  },
  dataTableHeaderSize: {
    defaultMessage: 'Tailles',
    description: 'Header name of the models data table in the product template',
  },
  dataTableHeaderTechnicalSheet: {
    defaultMessage: 'Fiches techniques',
    description: 'Header name of the models data table in the product template',
  },
});

const ModelsSection = ({ models }) => {
  const { formatMessage } = useIntl();
  const [expanded, setExpanded] = useState([]);

  const breakpoint = 1024;

  const handleClick = (itemIndex) => {
    const newArray = [...expanded];
    const arrayIndex = newArray.findIndex((index) => index === itemIndex);

    if (arrayIndex > -1) {
      newArray.splice(arrayIndex, 1);
    }

    if (arrayIndex < 0) {
      newArray.push(itemIndex);
    }

    setExpanded(newArray);
  };

  const nodes = models.map((model) => {
    let hasSizeVariation = false;
    let hasTechnicalSheet = false;

    const variants = model.variants.map((variant) => {
      let technicalSheet;
      let size;
      const sizeOption = variant.options.find(
        (option) => option._type === 'sizeVariation'
      );

      if (variant.technicalSheet && variant.technicalSheet.asset) {
        hasTechnicalSheet = true;

        technicalSheet = (
          <div
            css={`
              margin: auto;
              text-align: center;
            `}
          >
            <a
              href={variant.technicalSheet.asset.url}
              target='_blank'
              rel='noreferrer'
            >
              <IconDownload
                css={`
                  height: 1em;
                `}
              />
            </a>
          </div>
        );
      }

      if (sizeOption) {
        hasSizeVariation = true;

        const sizeArray = ['length', 'depth', 'height']
          .map((key) => {
            if (!sizeOption[key]) return false;

            const value = sizeOption[key] / 2.54;

            const integer = Math.floor(value);
            const decimal = value.toFixed(5) % integer;
            let denominator = 16;
            let numerator = Math.round(decimal * denominator);

            while (numerator && numerator % 2 === 0) {
              numerator /= 2;
              denominator /= 2;
            }

            let res = integer;

            if (numerator > 0 && numerator < denominator) {
              res += ` ${numerator}/${denominator}`;
            }

            return `${res}"`;
          })
          .filter((e) => e);

        size = sizeArray.join(' x ');
      }

      return {
        id: variant._key,
        model: variant.SKU,
        ...(sizeOption && { size }),
        ...(technicalSheet && { technicalSheet }),
      };
    });

    const slides = model.pictures.map((picture) => ({
      id: picture._key,
      key: picture._key,
      alt: picture.alt,
      caption: picture.caption,
      sourcePicture: [
        {
          ...picture.asset.slidePictureDesktop,
          media: greaterThanCondition(breakpoint),
        },
        {
          ...picture.asset.slidePictureMobile,
          sizes: '100vw',
        },
      ],
      sourceThumbnail: [
        {
          ...picture.asset.slideThumbnailDesktop,
          media: greaterThanCondition(breakpoint),
        },
        {
          ...picture.asset.slideThumbnailMobile,
          sizes: '100vw',
        },
      ],
    }));

    const dataTableHeaders = [
      {
        key: 'model',
        header: formatMessage(messages.dataTableHeaderModel),
      },
    ];

    if (hasSizeVariation) {
      dataTableHeaders.push({
        key: 'size',
        header: formatMessage(messages.dataTableHeaderSize),
      });
    }

    if (hasTechnicalSheet) {
      dataTableHeaders.push({
        key: 'technicalSheet',
        header: formatMessage(messages.dataTableHeaderTechnicalSheet),
      });
    }

    return {
      ...model,
      dataTableHeaders,
      slides,
      variants,
    };
  });

  return (
    <Container>
      <Wrapper>
        <h2 css={hideVisually}>{formatMessage(messages.sectionTitle)}</h2>

        <List>
          {nodes.map((node, index) => {
            return (
              <ListItem key={node._key}>
                <SideBarLayout
                  spaced
                  css={`
                    overflow: inherit;
                  `}
                >
                  <DescriptionContainer>
                    <Title>{node.name}</Title>

                    <SubTitle>{node.descriptiveName}</SubTitle>

                    <BlockContent blocks={node._rawShortDescription} />
                  </DescriptionContainer>

                  <SizesFormatsContainer>
                    {node.slides.length > 0 && (
                      <div>
                        <Gallery
                          slides={node.slides}
                          css={`
                            ${greaterThan(480)} {
                              margin-bottom: ${rem(88, 22)};
                            }
                          `}
                        />
                      </div>
                    )}

                    {node.variants.length > 0 && (
                      <>
                        <Button
                          primary
                          outlined
                          renderIcon={
                            <ToggleIcon
                              toggled={expanded.includes(index)}
                              label={
                                expanded.includes(index)
                                  ? formatMessage(
                                      messages.toggleIconLabelCollapse
                                    )
                                  : formatMessage(
                                      messages.toggleIconLabelExpand
                                    )
                              }
                            />
                          }
                          onClick={() => handleClick(index)}
                          css={`
                            z-index: 1;
                          `}
                        >
                          {formatMessage(messages.buttonText)}
                        </Button>

                        <Transition
                          items={expanded.includes(index)}
                          from={{
                            opacity: 0,
                            transform: 'translate3d(0,-40px,0)',
                          }}
                          enter={{
                            opacity: 1,
                            transform: 'translate3d(0,0px,0)',
                          }}
                          leave={{
                            opacity: 0,
                            transform: 'translate3d(0,-40px,0)',
                          }}
                        >
                          {(toggle) =>
                            toggle &&
                            ((props) => (
                              <DataTable
                                headers={node.dataTableHeaders}
                                rows={node.variants}
                                style={props}
                                css={`
                                  ${greaterThan(480)} {
                                    margin-top: ${rem(88, 22)};
                                  }
                                `}
                              />
                            ))
                          }
                        </Transition>
                      </>
                    )}
                  </SizesFormatsContainer>
                </SideBarLayout>
              </ListItem>
            );
          })}
        </List>
      </Wrapper>
    </Container>
  );
};

export default ModelsSection;

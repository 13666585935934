// vendors
import styled, { css } from 'styled-components';

// styles
import { magnifyStyle, h1Style } from '@styles/global';
import {
  sideBreakpoint,
  sideGutter,
} from '@components/SideBarLayout/SideBarLayout.styles';

// utils
import { lessThan, greaterThan } from '@utils/mediaQuery';

export const breakpoint = sideBreakpoint;

export const blockStyles = css`
  ${magnifyStyle}

  color: ${({ theme }) => theme.colors.white};
`;

export const Title = styled.h1`
  ${h1Style}

  color: ${({ theme }) => theme.colors.white};
`;

export const TextContainer = styled.div`
  padding-left: max(calc(50% - 1408px / 2 - ${sideGutter / 2}px), 24px);

  color: ${({ theme }) => theme.colors.white};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  height: 100%;

  ${lessThan(breakpoint)} {
    padding-right: 24px;
  }
`;

export const GalleryContainer = styled.div`
  ${greaterThan(breakpoint)} {
    padding-right: max(calc(50% - 1408px / 2 - ${sideGutter / 2}px), 24px);
  }

  ${lessThan(breakpoint)} {
    order: -1;
  }
`;

export const GalleryWrapper = styled.div`
  ${greaterThan(breakpoint)} {
    position: sticky;
    top: 88px;
  }
`;

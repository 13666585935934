// vendors
import React from 'react';
import PropTypes from 'prop-types';

import Button from '@components/Button';
import IconArrow from '@images/IconArrow';

const LinkAction = ({ href, internal, children }) => {
  const props = {
    tag: internal ? 'Link' : 'href',
  };
  return (
    <Button primary outlined to={href} renderIcon={<IconArrow />} {...props}>
      {children}
    </Button>
  );
};

LinkAction.propTypes = {
  href: PropTypes.string.isRequired,
  internal: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

LinkAction.defaultProps = {
  internal: false,
};

export default LinkAction;

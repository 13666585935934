import React from 'react';

const IconDownload = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35.303 35' {...props}>
    <g transform='translate(-137.348)'>
      <g transform='rotate(90 86 86)'>
        <path
          d='M0 0l14.605 17L0 34'
          transform='translate(12.282)'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
        />

        <path
          d='M0 0h25.663'
          transform='translate(0 17)'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
        />

        <path
          d='M0 0h34'
          transform='rotate(90 17 17)'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
        />
      </g>
    </g>
  </svg>
);

export default IconDownload;

// vendors
import styled from 'styled-components';
import { rem } from 'polished';

// utils
import { greaterThan } from '@utils/mediaQuery';

// styles
import { titleStyle, h2Style } from '@styles/global';

export const Container = styled.section`
  padding: ${rem(60, 16)} 24px;

  ${greaterThan(480)} {
    padding-top: ${rem(128, 22)};
    padding-bottom: ${rem(128, 22)};
  }
`;

export const Wrapper = styled.div`
  max-width: 1408px;

  margin: auto;
`;

export const DescriptionContainer = styled.div``;

export const SizesFormatsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  ${titleStyle}

  margin: 0;
`;

export const SubTitle = styled.h4`
  ${h2Style};

  margin: 4px 0 ${rem(30, 16)};

  font-weight: ${({ theme }) => theme.fontWeights.medium};

  ${greaterThan(480)} {
    margin: 8px 0 ${rem(60, 22)};

    font-weight: ${({ theme }) => theme.fontWeights.light};
  }
`;

export const List = styled.ul`
  padding: 0;

  list-style: none;

  > * + * {
    border-top: 2px solid currentColor;
  }
`;

export const ListItem = styled.li`
  padding: ${rem(60, 16)} 0;

  ${greaterThan(480)} {
    padding: ${rem(128, 22)} 0;
  }

  :first-child {
    padding-top: 0;
  }

  :last-child {
    padding-bottom: 0;
  }
`;

// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';

import Button from '@components/Button';
import IconDownload from '@images/IconDowload';

const messages = defineMessages({
  attachmentButtonAriaRoleDescription: {
    defaultMessage: 'Bouton de téléchargement de pièce jointe',
    description:
      'Aria role description of the attachment button in the product template',
  },
});

const DownloadAction = ({ fileName, url, children }) => {
  const { formatMessage } = useIntl();

  return (
    <Button
      aria-roledescription={formatMessage(
        messages.attachmentButtonAriaRoleDescription
      )}
      tag='href'
      primary
      outlined
      to={url}
      download={fileName}
      renderIcon={<IconDownload />}
    >
      {children}
    </Button>
  );
};

DownloadAction.propTypes = {
  fileName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default DownloadAction;

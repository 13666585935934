/* eslint-disable no-underscore-dangle */
// vendors
import React from 'react';
import { useMedia } from 'react-use';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { rem } from 'polished';

// utils
import {
  lessThanCondition,
  greaterThan,
  greaterThanCondition,
} from '@utils/mediaQuery';

// images
import IconArrow from '@images/IconArrow';

// components
import SideBarLayout from '@components/SideBarLayout';
import BlockContent from '@components/BlockContent';
import Gallery from '@components/Carousel';
import Button from '@components/Button';

// styles
import { css } from 'styled-components';
import {
  blockStyles,
  breakpoint,
  GalleryContainer,
  GalleryWrapper,
  TextContainer,
  TextWrapper,
  Title,
} from './HeroSection.styles';

const HeroSection = ({
  title,
  rawShortDescription,
  pictures,
  isConsumer,
  callToAction,
}) => {
  const isMobile = useMedia(lessThanCondition(breakpoint));

  const { formatMessage } = useIntl();

  const messages = defineMessages({
    contactPagePath: {
      defaultMessage: '/formulaire-de-contact',
      description: 'Path to the contact form page',
    },
  });

  const slides = pictures.map((current) => ({
    id: current.id,
    key: current.key,
    alt: current.alt,
    caption: current.caption,
    sourcePicture: [
      {
        ...current.picture.desktop,
        media: greaterThanCondition(breakpoint),
      },
      { ...current.picture.mobile, sizes: '100vw' },
    ],
    sourceThumbnail: [
      {
        ...current.thumbnail.desktop,
        media: greaterThanCondition(breakpoint),
      },
      { ...current.thumbnail.mobile, sizes: '100vw' },
    ],
  }));

  return (
    <SideBarLayout
      spaced
      css={`
        padding-bottom: ${rem(128, 22)};

        ${isConsumer
          ? css`
              background-color: ${({ theme }) => theme.colors.primary};
            `
          : css`
              background-color: ${({ theme }) => theme.colors.charcoal};
            `}

        ${greaterThan(breakpoint)} {
          overflow: inherit;
        }
      `}
    >
      <TextContainer>
        <TextWrapper>
          {!isMobile && (
            <Title
              css={`
                margin-top: ${rem(96, 22)};
              `}
            >
              {title}
            </Title>
          )}

          <div css={blockStyles}>
            <BlockContent blocks={rawShortDescription} />
          </div>

          {callToAction && callToAction.title && callToAction.title.length > 0 && (
            <Button
              outlined
              white
              renderIcon={<IconArrow />}
              to={callToAction.href}
              tag={callToAction.internalLink ? 'link' : 'href'}
              css={`
                margin-top: ${rem(60, 22)};

                max-width: 488px;
              `}
            >
              {callToAction.title}
            </Button>
          )}

          {!callToAction && !isConsumer && (
            <Button
              outlined
              white
              renderIcon={<IconArrow />}
              to={formatMessage(messages.contactPagePath)}
              tag='link'
              css={`
                margin-top: ${rem(60, 22)};

                max-width: 488px;
              `}
            >
              <FormattedMessage
                defaultMessage='Demande de soumission'
                description='CTA button to contact page'
              />
            </Button>
          )}
        </TextWrapper>
      </TextContainer>

      {slides.length > 0 && (
        <GalleryContainer>
          <GalleryWrapper>
            {isMobile && (
              <Title
                css={`
                  padding: 0 24px;
                `}
              >
                {title}
              </Title>
            )}

            <Gallery slides={slides} aria-label={title} />
          </GalleryWrapper>
        </GalleryContainer>
      )}
    </SideBarLayout>
  );
};

HeroSection.propTypes = {
  title: PropTypes.string.isRequired,
  rawShortDescription: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isConsumer: PropTypes.bool,
  callToAction: PropTypes.shape({
    title: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    internalLink: PropTypes.bool.isRequired,
  }),
  pictures: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
      picture: PropTypes.shape({
        desktop: PropTypes.shape({
          aspectRatio: PropTypes.number.isRequired,
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired,
          srcSetWebp: PropTypes.string.isRequired,
          srcWebp: PropTypes.string.isRequired,
        }).isRequired,
        mobile: PropTypes.shape({
          aspectRatio: PropTypes.number.isRequired,
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired,
          srcSetWebp: PropTypes.string.isRequired,
          srcWebp: PropTypes.string.isRequired,
        }).isRequired,
      }),
      thumbnail: PropTypes.shape({
        desktop: PropTypes.shape({
          aspectRatio: PropTypes.number.isRequired,
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired,
          srcSetWebp: PropTypes.string.isRequired,
          srcWebp: PropTypes.string.isRequired,
        }).isRequired,
        mobile: PropTypes.shape({
          aspectRatio: PropTypes.number.isRequired,
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired,
          srcSetWebp: PropTypes.string.isRequired,
          srcWebp: PropTypes.string.isRequired,
        }).isRequired,
      }),
    })
  ).isRequired,
};

HeroSection.defaultProps = {
  isConsumer: false,
  callToAction: undefined,
};

export default HeroSection;

import styled, { css } from 'styled-components';

const tableText = css`
  font-weight: ${({ theme }) => theme.dataTable.fontWeight};
  line-height: ${({ theme }) => theme.dataTable.lineHeight};
  text-align: ${({ theme }) => theme.dataTable.textAlign};
`;

export const TableHead = styled.thead`
  ${tableText}
`;

export const TableBody = styled.tbody`
  ${tableText}
`;

export const TableHeader = styled.th`
  padding-left: ${({ theme }) => theme.dataTable.padding}rem;

  color: ${({ theme }) => theme.dataTable.header.color};

  background-color: ${({ theme }) => theme.dataTable.header.bg};
`;

export const TableRow = styled.tr`
  height: ${({ theme }) => theme.dataTable.row.height}rem;

  :nth-child(2n + 1) td {
    background-color: ${({ theme }) => theme.dataTable.alt.bg};

    :not(:last-child)::after {
      background-color: ${({ theme }) => theme.dataTable.alt.borderColor};
    }
  }
`;

export const TableCell = styled.td`
  position: relative;

  min-width: 100px;

  padding-left: ${({ theme }) => theme.dataTable.padding}rem;

  color: ${({ theme }) => theme.dataTable.color};

  background-color: ${({ theme }) => theme.dataTable.bg};

  :not(:last-child)::after {
    position: absolute;
    top: 15%;
    right: 0;

    width: 2px;
    height: 70%;

    background-color: ${({ theme }) => theme.dataTable.borderColor};

    content: '';
  }
`;

export const Table = styled.table`
  width: 100%;

  border-collapse: collapse;
  border-spacing: 0;
`;
